@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
:root {
  --white: #eaeaea;
  --black: #050505;
  --sobre: #ee258d;
  --habilidades: #fbe31a;
  --trabalhos: #03aedb;
  --contato: #6f37b7;
  --app-details: #8f9690;
  --main-color: #050505;
  --highlight-color: #050505;

  --image-grid-height: 50vh;
  --image-grid-half-height: calc( var(--image-grid-height)/2 * 1px);
  --image-grid-half-height: calc( var(--image-grid-height)*2 * 1px);
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #050505;

  background-color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animated-text{
    display: flex;
    white-space: pre;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.animated-text.done .letter{
    opacity: 1;
    -webkit-animation: none !important;
            animation: none !important;
    transform: translateY(0);
}

.animated-text .letter{
    opacity: 0;
    -webkit-animation: show-letter 0.7s ease-out forwards;
            animation: show-letter 0.7s ease-out forwards;
}

@-webkit-keyframes show-letter{
    0%{
        opacity: 0;
        transform: translateY(50px);
    }
    30%{
        opacity: 1;
        transform: translateY(-20px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes show-letter{
    0%{
        opacity: 0;
        transform: translateY(50px);
    }
    30%{
        opacity: 1;
        transform: translateY(-20px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.text-carousel{
    position: relative;
}

.text-carousel .animated-text{
    position: relative;
    -webkit-filter: drop-shadow(0 0 1px var(--black));
            filter: drop-shadow(0 0 1px var(--black));
    z-index: 5;
}

.text-carousel .underline{
    position: absolute;
    bottom: 10%;
    left: 5%;

    width: 0px;
    height: 4px;
    background-color: var(--white);
    border-radius: 15px;

    -webkit-animation: underline 0.5s ease-out forwards;

            animation: underline 0.5s ease-out forwards;
    transform-origin: left;

    z-index: 1;
}

@-webkit-keyframes underline{
    to{
        width: 90%;
    }
}

@keyframes underline{
    to{
        width: 90%;
    }
}
.page-arrow{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    background-color: transparent;
    color: var(--white);
    font-size: 50px;

    cursor: pointer;
}

.page-arrow div{
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-arrow span{
    position: relative;
    left: 0;
    transform: translateX(-30%);

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    margin-right: 200%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 0.5em;
    font-weight: 600;
    line-height: 1em;
    text-align: right;
    text-transform: lowercase;

    opacity: 0;

    transition: all 0.3s ease-out;
}

.page-arrow.split span{
    transform: none;
    width: 100px;
    margin-right: 125px;
}

.page-arrow:hover span{
    opacity: 1;
    transform: translateX(-40%);
}

.page-arrow.split:hover span{
    opacity: 1;
    margin-right: 150px;
    transform: none;
}
.page{
    position: relative;
    
    padding: min(60px, 8vw) !important;
    min-height: 100%;
    min-width: 100%;

    box-sizing: border-box;
}

@media (max-width:500px){
    .page{
        padding: 30px !important;
    }
}
.home{
    position: relative;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: var(--white);
}

.home #logo{
    fill: var(--white);
}

.home .card{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    width: 50vw;
    height: 30vh;
    z-index: 50;
}

.home .card .animated-text{
    font-size: max(1.8em, min(3.5vw, 11vh));
    margin: 5px;
    letter-spacing: -0.2vw;
}

.home .card .animated-text.name{
    position: relative;
    font-size: max(2.5em, 5.5vw);
    margin: 0;
    letter-spacing: -0.3vw;
    line-height: max(0.5em, 2.7vw);
    font-weight: 700;

    -webkit-filter: drop-shadow(0 0 1px rgba(0,0,0,0.8));

            filter: drop-shadow(0 0 1px rgba(0,0,0,0.8));
}

.home .card .animated-text.name::after{
    content: "GUILHERME H. SCARPEL";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    z-index: 1000;
}

.home .card .i-am, .home .card .hi{
    display: flex;
}

.home .card .adjectives{
    position: relative;
    margin-left: 2px;
}

.home .hand-emoji{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    
    height: 60vh;
    transform-origin: center bottom;

    -webkit-animation: wave 1.2s ease-out forwards;

            animation: wave 1.2s ease-out forwards;
}

.home .languages-container{
    position: absolute;
    top: 5px;
    right: 60px;

    z-index: 10000;
    opacity: 0;
    -webkit-animation: show-right 0.5s ease-out 1s forwards;
            animation: show-right 0.5s ease-out 1s forwards;
}

.home .languages{
    position: relative;

    background-color: transparent;
    border: 2px solid var(--white);
    color: var(--white);
    padding: 0px 5px;
    padding-left: 1.65em;
    border-radius: 5px;

    text-transform: uppercase;
    font-family: inherit;
    font-weight: 500;
    font-size: 1.25em;
    letter-spacing: 1px;
    width: 210px;
    height: 38px;

    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;

    cursor: pointer;
    z-index: 1000;
}

.home .languages:focus{
    outline: none;
}

.home .languages *{
    background-color: var(--black);
    text-transform: uppercase;
}

.home .globe{
    position: absolute;
    top: 50%;
    left: 9px;
    transform: translateY(-50%);

    font-size: 1.6em;
}

.home .drop-arrow{
    position: absolute;
    top: 7px;
    right: 7px;

    font-size: 1.4em;

    color: var(--white);

    z-index: 100;
}

@media (max-width: 500px){
    .home .hand-emoji{
        left: 53%;
        max-width: 75vw;
        max-height: 80vh;
        height: auto;
    }

    .home .card .animated-text{
        font-size: 6vw;
        margin: 5px;
        letter-spacing: -0.1vw;
    }

    .home .card .animated-text.name{
        flex-wrap: wrap;
        font-size: 11vw;
        line-height: 11vw;
        width: 85%;
        height: 21vw;
        margin: -5px 0;
    }

    .home .card .animated-text.my-name-is{
        margin-left: -1px;
    }

    .home .i-am .adjectives{
        margin-left: -10px;
    }

    .home .text-carousel .underline{
        bottom: 5px;
        height: 3px;
    }

    .home .bubbles{
        display: none;
    }

    .home .card{
        width: 90vw;
        margin-left: max(-15px, -15%);
    }

    .home .languages-container{
        top: 6px;
        right: 40px;

        font-size: 0.8rem;
    }
    
    .home .languages{
        width: 150px;
        padding-left: 5px;
    }

    .home .globe{
        display: none;
    }
}

@media (max-width: 580px){
    .home .page-arrow{
        display: none;
    }
}

@-webkit-keyframes wave{
    0%{
        transform: translate(-50%, -55%) scale(0);
        opacity: 0;
    }
    25%{
        transform: translate(-50%, -55%) scale(1);
        opacity: 1;
    }
    40%{
        transform: translate(-50%, -55%) scale(1) rotate(-15deg);
        opacity: 1;
    }
    60%{
        transform: translate(-50%, -55%) scale(1) rotate(15deg);
        opacity: 1;
    }
    85%{
        transform: translate(-50%, -55%) scale(1) rotate(-10deg);
        opacity: 1;
    }
    100%{
        transform: translate(-50%, -90%) scale(0);
        opacity: 0;
    }
}

@keyframes wave{
    0%{
        transform: translate(-50%, -55%) scale(0);
        opacity: 0;
    }
    25%{
        transform: translate(-50%, -55%) scale(1);
        opacity: 1;
    }
    40%{
        transform: translate(-50%, -55%) scale(1) rotate(-15deg);
        opacity: 1;
    }
    60%{
        transform: translate(-50%, -55%) scale(1) rotate(15deg);
        opacity: 1;
    }
    85%{
        transform: translate(-50%, -55%) scale(1) rotate(-10deg);
        opacity: 1;
    }
    100%{
        transform: translate(-50%, -90%) scale(0);
        opacity: 0;
    }
}

@-webkit-keyframes hide-over{
    from{
        height: 100vh;
    }
    to{
        height: 0;
    }
}

@keyframes hide-over{
    from{
        height: 100vh;
    }
    to{
        height: 0;
    }
}
.bubbles{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    width: 40vw;
    height: 40vw;
}

.bubbles *{
    border-radius: 50%;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(32, 0, 15, 0.5));
            filter: drop-shadow(0px 0px 10px rgba(32, 0, 15, 0.5));
    z-index: 5;
}

.bubbles .b-1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 30vw;
    height: 30vw;

    -webkit-animation: bubble1 0.7s ease;

            animation: bubble1 0.7s ease;
}

.bubbles .b-2{
    position: absolute;
    bottom: 5%;
    left: 10%;

    width: 7vw;
    height: 7vw;

    opacity: 0;
    -webkit-animation: bubble2 1.5s ease 0.5s forwards;
            animation: bubble2 1.5s ease 0.5s forwards;
}

.bubbles .b-3{
    position: absolute;
    right: 12%;
    bottom: 19%;

    width: 5vw;
    height: 5vw;

    opacity: 0;
    -webkit-animation: bubble3 1.2s ease 1s forwards;
            animation: bubble3 1.2s ease 1s forwards;
}

.bubbles .b-4{
    position: absolute;
    right: 18%;
    top: 0%;

    width: 8vw;
    height: 8vw;
    
    z-index: 2;

    opacity: 0;
    -webkit-animation: bubble4 1.2s ease 0.8s forwards;
            animation: bubble4 1.2s ease 0.8s forwards;
}

@-webkit-keyframes bubble1{
    0%{
        transform: translate(-50%, -50%) scale(0);
    }
    60%{
        transform: translate(-50%, -50%) scale(1.2);
    }
    100%{
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes bubble1{
    0%{
        transform: translate(-50%, -50%) scale(0);
    }
    60%{
        transform: translate(-50%, -50%) scale(1.2);
    }
    100%{
        transform: translate(-50%, -50%) scale(1);
    }
}

@-webkit-keyframes bubble2{
    0%{
        transform: translate(30%, 30%) scale(0);
        opacity: 0;
        z-index: 2;
    }
    70%{
        transform: translate(420%, -350%) scale(1);
        z-index: 30;
        opacity: 1;
        z-index: 2;
    }
    71%{
        z-index: 30;
    }
    100%{
        opacity: 1;
    }
}

@keyframes bubble2{
    0%{
        transform: translate(30%, 30%) scale(0);
        opacity: 0;
        z-index: 2;
    }
    70%{
        transform: translate(420%, -350%) scale(1);
        z-index: 30;
        opacity: 1;
        z-index: 2;
    }
    71%{
        z-index: 30;
    }
    100%{
        opacity: 1;
    }
}

@-webkit-keyframes bubble3{
    0%{
        transform: translate(0,0) scale(0);
        opacity: 0;
        z-index: 2;
    }
    50%{
        transform: translate(-600% , -400%) scale(1);
        z-index: 30;
        opacity: 1;
        z-index: 2;
    }
    51%{
        z-index: 30;
    }
    100%{
        opacity: 1;
    }
}

@keyframes bubble3{
    0%{
        transform: translate(0,0) scale(0);
        opacity: 0;
        z-index: 2;
    }
    50%{
        transform: translate(-600% , -400%) scale(1);
        z-index: 30;
        opacity: 1;
        z-index: 2;
    }
    51%{
        z-index: 30;
    }
    100%{
        opacity: 1;
    }
}

@-webkit-keyframes bubble4{
    0%{
        transform: translate(0,0) scale(0);
        opacity: 0;
        z-index: 30;
    }
    5%{
        opacity: 0;
    }
    50%{
        transform: translate(-320% , 370%) scale(1);
        z-index: 30;
        opacity: 1;
    }
    51%{
        z-index: 2;
    }
    100%{
        opacity: 1;
    }
}

@keyframes bubble4{
    0%{
        transform: translate(0,0) scale(0);
        opacity: 0;
        z-index: 30;
    }
    5%{
        opacity: 0;
    }
    50%{
        transform: translate(-320% , 370%) scale(1);
        z-index: 30;
        opacity: 1;
    }
    51%{
        z-index: 2;
    }
    100%{
        opacity: 1;
    }
}
.presentation{
    width: 45vw !important;
    height: min(650px, 72vh) !important;
}

.presentation .left-card{
    position: relative;
    height: 100%;
}

.presentation .me-img {
    position: absolute;
    left: 10vw;
    bottom: 5vw;
    height: min(600px, 65vh);

    z-index: 10;

    -webkit-animation: slide 1s ease-out;

            animation: slide 1s ease-out;
}

.presentation .name, .presentation .upper-name{
    position: absolute;
    left: 0;
    bottom: 0.65em;

    color: var(--sobre);
    font-size: max(4.25em, 8vw);
    line-height: max(0.8em, 6vw);
    letter-spacing: -0.3vw;
    font-weight: 700;
    text-transform: uppercase;

    z-index: 5;
}

.presentation .upper-name{
    z-index: 15;

    color: transparent;
    -webkit-text-stroke: 2px var(--sobre);
}

.presentation .additional-info{
    position: absolute;
    left: 0;
    bottom: 0px; 

    font-size: max(2.3em, 3.5vw);
    font-weight: 600;
    letter-spacing: min(-2px, -0.2vw);

    color: var(--white);

    z-index: 15;
}

@media (max-width: 800px){
    .presentation{
        width: min(400px, 95vw) !important;
        height: 75vh;
        margin-left: 1vw;
    }

    .presentation .me-img {
        bottom: max(45px, 7vw);
    }

    .presentation .name, .presentation .upper-name{
        bottom: 11%;

        font-size: min(17vw, 4.5em);
        line-height: max(0.8em, 6vw);
        letter-spacing: -0.3vw;
        font-weight: 700;
    }
}

@-webkit-keyframes slide{
    0%{
        transform: translateX(25vw);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slide{
    0%{
        transform: translateX(25vw);
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
}
.certificades{
    display: grid;

    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat( auto-fit, 350px );
    grid-auto-flow: column;
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 350px;
    padding-right: 50px;
}
.img-following-text{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 25px;
    width: 300px;

    border-top: 1px solid var(--white);
    border-bottom: 1px solid var(--white);
    color: var(--white);

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
    cursor: pointer;
}

.img-following-text::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: violet;
    opacity: 0;

    z-index: 50;
}

.img-following-text span{
    text-transform: lowercase;
    text-align: left;
    font-size: 1.5em;
    font-weight: 600;

    color: var(--white);
    
    z-index: 10;
}

.img-following-text img{
    position: absolute;

    max-width: 50%;
    max-height: 90%;

    z-index: 5;

    opacity: 0;
    transition: all 0.5s ease-out;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.img-following-text:hover img{
    opacity: 1;
}
.scrollable{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100vh;
}
.about{
    display: flex;
    position: relative;
    height: 80vh;
    max-height: 900px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    padding-top: 50px;
    padding-right: 100px;
}

.about .glitch-text{
    color: var(--sobre);
}

.about .glitch-text .auxiliar-text{
    -webkit-text-stroke-color: var(--sobre);
}

.about .navbar .menu-icon.clicked div{
    background-color: var(--black);
}

.about .navbar .menu-icon:hover div{
    background-color: var(--sobre);
}

.about .text-block{
    display: flex;
    width: 40vw;
    height: 65%;
    transform: translateX(0);

    text-align: justify;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--white);
    opacity: 1;

    padding: 25px 50px;
    
    transition: all 0.6s ease-out 0.3s;
}

.about .hidden .text-block{
    opacity: 0;
    transform: translateX(150px);
}

.about .hidden .certificades{
    opacity: 0;
}

.text-block p{
    padding: 0;
    margin: 0;
}

.text-block em{
    color: var(--sobre);
    font-weight: 700;
    font-style: normal;
}

.text-block:nth-child(odd){
    align-items: flex-end;
}

.about .lottie-container{
    height: min(500px, 60vh);
    width: min(500px, 60vh);
}

.about .about-me{
    position: relative;
    display: flex;

    width: 48vw;
    margin-left: 5vw;
    padding-left: min(550px, 15vw);
}

.about .about-me .lottie-container.statue{
    position: absolute;
    bottom: -5%;
    left: 0;
}

.about .education{
    position: relative;
    display: flex;

    width: 48vw;
    margin-left: -5vw;
}

.about .education .text-block{
    align-self: flex-end;
    height: auto;
    margin-bottom: 5%;
}

.about .education .lottie-container.graduation{
    position: absolute;
    top: -7%;
    left: 50%;
    transform: translateX(-50%);
}

.about .certificades-container{
    position: relative;

    display: flex;

    margin-left: -5vw;
}

.about .certificades-container .lottie-container{
    position: absolute;
    bottom: 5%;
    left: 10vw;
}

.about .certificades-container .certificades{
    align-self: center;
    margin-bottom: 55px;
    margin-left: 50px;
}

@media (max-width: 800px){
    .about{
        height: 80vh;
    }

    .about .hidden .text-block{
        opacity: 1;
        transform: none;
    }
    
    .about .hidden .certificades{
        opacity: 1;
    }

    .about .about-me, .about .education, .about .certificades-container{
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    
        width: -webkit-fit-content;
    
        width: -moz-fit-content;
    
        width: fit-content;
        height: 100%;
        margin-right: -40px;
    }

    .about .lottie-container{
        height: min(90vw, 60vh);
        width: min(90vw, 60vh);

        align-self: flex-end;
        position: relative;
        bottom: 0;
        left: 0;
    }

    .about .about-me, .about .education, .about .certificades-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 5vw;
    
        width: -webkit-fit-content;
    
        width: -moz-fit-content;
    
        width: fit-content;
        height: 100%;
        margin-left: max(100px, 5vw);
        padding-left: 0;
    }

    .about .about-me .lottie-container.statue, .about .education .lottie-container.graduation,
    .about .certificades-container .lottie-container{
        align-self: flex-end;
        position: relative;
        bottom: 5%;
        left: 0;
    }

    .about .text-block{
        display: flex;
        width: min(80vw, 400px);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        transform: translateX(0);

        padding: 0;
        margin: 0;
    }

    .about .about-me .text-block, .about .education .text-block, .about .certificades-container .text-block{
        align-self: flex-start;
        position: relative;
        top: 5%;
    }

    .about .education .lottie-container.graduation{
        transform: none;
    }
}
.image-grid{
    display: flex;

    height: calc(min(500px, 64vh) + 10px);
}

.image-grid.mock .square, .image-grid.mock .panorama, .image-grid.mock .portrait{
    background-color: rgb(77, 77, 77);
    border-radius: 5px;

    animation: flicker 1.5s ease alternate-reverse infinite;
}

.image-grid img{
    position: relative;

    border-radius: 5px;
    object-fit: cover;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    opacity: 1;

    transition: opacity 0.5s ease-out;
    cursor: pointer;

    -webkit-animation: show-up 1s ease-out;

            animation: show-up 1s ease-out;
}

.image-grid img:hover{
    opacity: 0.5;
}

.image-grid .square{
    width: min(250px, 32vh);
    height: min(250px, 32vh);
}

.image-grid .panorama{
    width: auto;
    height: min(250px, 32vh);
}

.image-grid .portrait{
    width: min(250px, 32vh);
    height: 100%;
}

.image-grid .squares-panorama, .image-grid .squares-panorama-reversed{
    display: flex;
    flex-direction: column;
}

.image-grid .squares-panorama-reversed{
    flex-direction: column-reverse;
}

.image-grid .squares{
    display: flex;
    flex-direction: row;
}

.image-grid .two-squares{
    display: flex;
    flex-direction: column;
}

.image-grid .bigger-square img{
    width: min(500px, 64vh);
    height: 100%;
}

.image-grid .two-panoramas{
    display: flex;
    flex-direction: column;
}

.image-grid .bigger-panorama img{
    width: min(1000px, 128vh);
    height: 100%;
}

@-webkit-keyframes flicker{
    from{
        opacity: 0.3;
    }
    to{
        opacity: 1;
    }
}

@keyframes flicker{
    from{
        opacity: 0.3;
    }
    to{
        opacity: 1;
    }
}

@-webkit-keyframes show-up{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes show-up{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.image img{
    position: absolute;
    -webkit-animation: set-img 0.5s ease-out forwards;
            animation: set-img 0.5s ease-out forwards;
}

.designs{
    position: relative;
    z-index: 1000;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.designs .image-grid{
    margin-right: 15px !important;
}

@-webkit-keyframes set-img{
    to{
        top: 50%;
        left: 50%;
        transform: translate(-50% -50%);
        width: 100vw;
    }
}

@keyframes set-img{
    to{
        top: 50%;
        left: 50%;
        transform: translate(-50% -50%);
        width: 100vw;
    }
}

@media (max-width: 500px){
    .designs .image-grid{
        padding-left: 15px;
    }
}

@media (min-width: 500px){
    .designs .image-grid{
        padding-right: min(60px, 8vw) !important;
    }
}
.job-page{
    position: relative;
    display: flex;

    margin-top: 25px;
    margin-right: 50px;
    z-index: 15;
}

.job-page .job-desc{
    width: 25vw;
    text-align: left;
    height: 100%;
    margin-right: min(50px, 5vw);
}

.job-page .job-desc h1, .job-page .job-desc .animated-text{
    margin: 0;

    width: 15vh;

    font-weight: 600;
    font-size: 5.25vw;
    letter-spacing: -0.3vw;
    line-height: 4.5vw;

    color: var(--trabalhos);
}

.job-page .job-desc p{
    color: var(--white);

    font-size: 1.25em;
    line-height: 1.25em;
    font-weight: 400;
    text-align: justify;

    -webkit-animation: show-bottom 0.5s ease-out;

            animation: show-bottom 0.5s ease-out;
}

@media (max-width: 700px){
    .job-page{
        margin-top: 35px;
        margin-left: min(15px, 4vw);
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        grid-gap: max(4vw, 25px) !important;
        gap: max(4vw, 25px) !important;
    }

    .job-page .job-desc{
        width: min(75vw, 250px);
    }

    .job-page .job-desc h1, .job-page .job-desc .animated-text{
        font-size: min(13vw, 3em);
        letter-spacing: -0.3vw;
        line-height: 4.5vw;
    }
}

@media (max-width: 500px){
    .job-page{
        margin-left: -5px;
    }
}
.video-card{
    display: flex;
    align-items: center;

    height: 175px;
    width: 550px;
    background-color: var(--white);
    border-radius: 15px;

    padding: 3px 15px;
}

.video-card a{
    text-decoration: none;
    color: inherit;
}

.video-card .video-media{
    position: relative;

    display: flex;

    width: 300px;
    height: 145px;

    background-color: transparent;
}

.video-card .video-media img{
    object-fit: contain;
    width: 100%;

    border-radius: 10px;

    -webkit-filter: drop-shadow(0 3px 6px rgba(41, 38, 38, 0.3));

            filter: drop-shadow(0 3px 6px rgba(41, 38, 38, 0.3));

    transition: -webkit-filter 0.5s ease-out;

    transition: filter 0.5s ease-out;

    transition: filter 0.5s ease-out, -webkit-filter 0.5s ease-out;
}

.video-card .video-media img:hover{
    -webkit-filter: drop-shadow(0 3px 10px rgba(58, 61, 68, 0.5));
            filter: drop-shadow(0 3px 10px rgba(58, 61, 68, 0.5));
}

.video-card .video-media .duration{
    position: absolute;
    bottom: 10px;
    right: 15px;

    padding: 3px 8px;
    border-radius: 8px;
    color: var(--white);
    font-weight: 500;
    font-size: 0.9em;

    background-color: var(--black);

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.video-card .video-info{
    width: 60%;
}

.video-card .video-info .video-name{
    margin: 0;

    text-align: left;
    font-size: 1.6em;
    font-weight: 600;
    letter-spacing: -0.05em;

    transition: color 0.4s ease-out;
}

.video-card .video-info .video-name:hover{
    color: var(--trabalhos);
}

.video-card .video-info .video-description{
    margin: 0;

    font-size: 0.9em;
    line-height: 1.25em;
    text-align: justify;
}

.video-card .video-info .video-tools{
    margin-top: 10px;

    display: flex;
    font-size: 1.5em;

    color: var(--black);
}

@media (max-width: 1000px){
    .video-card{
        display: grid;
        grid-template-rows: 45% 55%;
    
        height: 100%;
        width: min(90vw, 300px);
        background-color: var(--white);
        border-radius: 15px;
    
        padding: 15px 10px;
        box-sizing: border-box;
    }

    .video-card .video-media{
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;

        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .video-card .video-media img{
        object-fit: cover;
        margin: 0 auto;
        margin-left: 8px;
    }

    .video-card .video-media .duration{
        bottom: 15px;
        right: 25px;
    }

    .video-card .video-info{
        width: 90%;
        height: 100%;
        margin: 0 auto;
    }

    .video-card .video-info .video-name{
        text-align: center;
        margin-bottom: -20px;
    }
    
    .video-card .video-info .video-description{
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        width: 100%;
    }

    .video-card .video-info .video-tools{
        align-items: center;
        justify-content: center;
    }

}

@media (min-width: 1000px){
    .video-card .video-media{
        margin-right: 15px;
    }
}
.videos .videos-container{
    display: grid;

    grid-template-rows: repeat(2, 175px) !important;
    grid-template-columns: repeat( auto-fit, 550px );
    grid-auto-flow: column;
    grid-gap: 20px;
    gap: 20px;
}

.videos .videos-container .video-card{
    opacity: 0;
    max-height: 400px;
    -webkit-animation: show-video 0.5s ease-out forwards;
            animation: show-video 0.5s ease-out forwards;
}

@-webkit-keyframes show-video{
    from{
        opacity: 0;
        transform: translateX(300px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes show-video{
    from{
        opacity: 0;
        transform: translateX(300px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 1000px){
    .videos .videos-container{
        display: flex;
        flex-direction: row !important;
        flex-wrap: unset;

        height: 100%;
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important;

        margin-right: min(40vw, 200px) !important;
        padding-right: max(150px, 15vw) !important;
    }

    .videos .videos-container *:not(:last-child){
        margin-right: 15px;
    }

    .videos .videos-container .video-card{
        opacity: 0;
        -webkit-animation: show-bottom 0.5s ease-out forwards;
                animation: show-bottom 0.5s ease-out forwards;
    }
}
.app-card{
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: min(25px, 1.5vw);
    gap: min(25px, 1.5vw);
    align-items: flex-start;

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
}

.app-card .app-img{
    width: 100%;
}

.app-card .app-img img{
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.app-card .app-info{
    color: var(--white);
    text-align: left;
}

.app-card.right .app-info *{
    -webkit-animation-name: show-left !important;
            animation-name: show-left !important;
}

.app-card.left .app-info *{
    -webkit-animation-name: show-right !important;
            animation-name: show-right !important;
}

.app-card .app-info .app-tools{
    position: relative;
    display: flex;

    font-size: max(1.8em, 2vw);
    line-height: 2vw;

    margin-bottom: min(20px, 2.5vh);
    padding-left: 5px;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;

    opacity: 0;
    -webkit-animation: show-left 0.5s ease-out 0.1s forwards;
            animation: show-left 0.5s ease-out 0.1s forwards;
}

.app-card .app-info .app-tools .tool-icon{
    text-transform: capitalize;
}

.app-card .app-info .app-title{
    margin: 0;

    font-size: 7.5vw;
    font-weight: 600;
    letter-spacing: -0.5vw;
    line-height: 6vw;

    opacity: 0;
    -webkit-animation: show-left 0.5s ease-out 0.2s forwards;
            animation: show-left 0.5s ease-out 0.2s forwards;
}

.app-card .app-info .app-desc{
    margin: 0;
    margin-top: min(15px, 2vw);
    margin-bottom: min(30px, 4vh);

    font-size: 1.5vw;
    letter-spacing: -0.05vw;
    line-height: 2vw;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;

    opacity: 0;
    -webkit-animation: show-left 0.5s ease-out 0.3s forwards;
            animation: show-left 0.5s ease-out 0.3s forwards;
}

.app-card .app-info button{
    position: relative;

    border: 1px solid var(--white);
    background-color: transparent;
    border-radius: 5px;

    color: var(--white);
    font-size: 1.1vw;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: 0.1vw;

    padding: 0.7vw 2vw;

    transition: all 0.3s ease-out 0.2s;

    cursor: pointer;

    opacity: 0;
    -webkit-animation: show-left 0.5s ease-out 0.4s forwards;
            animation: show-left 0.5s ease-out 0.4s forwards;
}

.app-card .app-info button:hover{
    color: var(--black);
}

.app-card .app-info button::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 0%;
    height: 100%;
    
    background-color: var(--white);
    transform-origin: left;

    z-index: -1;

    transition: all 0.3s ease-out;
}

.app-card .app-info button:hover::after{
    width: 100%;
}

@media (max-width: 700px){
    .app-card{
        display: flex;
        flex-direction: column;
        grid-gap: 1.5vw;
        gap: 1.5vw;
        align-items: center;
        justify-content: center;
    
        height: -webkit-fit-content;
    
        height: -moz-fit-content;
    
        height: fit-content;
        box-sizing: border-box;
    }

    .app-card .app-img{
        width: 60%;
    }

    .app-card .app-info{
        text-align: center;
    }

    .app-card .app-info .app-tools{
        display: none;
    }

    .app-card .app-info .app-title{
        margin: 0;
        font-size: min(5em, 20vw);
        letter-spacing: -1vw;
        line-height: max(0.8em, 5vw);
    }

    .app-card .app-info .app-desc{
        margin: 2vh auto;
        font-size: min(1em, 5vw);
        letter-spacing: -0.05vw;
        line-height: min(1.2em, 6vw);
        text-align: justify;

        -webkit-line-clamp: 10;
        width: 60vw !important;
    }

    .app-card .app-info button{
        font-size: min(1em, 6vw);
        padding: 0.7vw 2vw;
    }
}

@media (min-width: 700px) and (max-width: 1000px){
    .app-card .app-info .app-tools{
        grid-gap: 10px;
        gap: 10px;
        font-size: 2.5vw;
        line-height: 3vw;
    
        padding-left: 5px;
    }

    .app-card .app-info .app-title{
        font-size: 10vw;
        font-weight: 600;
        letter-spacing: -0.5vw;
        line-height: 8vw;
    }

    .app-card .app-info .app-desc{
        text-align: justify;
        width: 95%;

        margin: 2vh 0 3vh 0;
        font-size: 2vw;
        line-height: 2.6vw;

        -webkit-line-clamp: 6;
    }

    .app-card .app-info button{
        font-size: min(1.5em, 1.8vw);
        letter-spacing: 0.1vw;
    
        padding: 0.7vw 2vw;
    }
}

@-webkit-keyframes pop-up-icon{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes pop-up-icon{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.apps{
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: -25px;
    padding: 0 10vw;
    box-sizing: border-box;

    position: relative;

    height: calc(100vh - min(120px, 20vw) - 55px) !important;
    width: calc(100vw - min(120px, 20vw)) !important;
}

.apps .app-card{
    -webkit-animation: swipe-right 0.5s ease-out forwards;
            animation: swipe-right 0.5s ease-out forwards;
}

.apps .apps-index{
    position: absolute;
    left: 5%;
    bottom: 0%;

    display: flex;
    grid-gap: 15px;
    gap: 15px;

    color: var(--white);
    font-size: min(12em, 12vw);
    font-weight: 500;
    letter-spacing: -1vw;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;

    -webkit-filter: drop-shadow(0px 0px 1px rgba(0,0,0,0.3));

            filter: drop-shadow(0px 0px 1px rgba(0,0,0,0.3));
}

.apps .apps-index .total-indexes{
    color: transparent;
    font-weight: 700;
    -webkit-text-stroke: 2px var(--white);
}

.apps .arrow-btn{
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    width: min(45px, 7vh);
    height: min(45px, 7vh);
    padding: 0;

    font-size: 7vh;
    color: var(--white);
    background-color: transparent;
    border: none;

    transition: all 0.5s ease-out, opacity 0.8s ease-out;
    cursor: pointer;

    z-index: 500;
}

.apps .arrow-btn:hover{
    opacity: 0.5;
}

.apps .arrow-btn:focus{
    outline: none;
    opacity: 0.5;
}

.apps .arrow-btn:disabled{
    opacity: 0;
    cursor: default;
}

.apps .arrow-btn.left{
    left: 0;
}

.apps .arrow-btn.right{
    right: 0;
}

.apps .slide{
    position: absolute;
    bottom: 50px;
    left: 53%;
    transform: translateX(-50%);

    color: var(--white);
    width: 50%;
    text-transform: uppercase;
    font-size: 0.95em;

    display: none;
}

@media(max-width: 499px){
    .apps{
        width: calc(100vw - 60px) !important;
        margin-top: -75px !important;
        padding: 0 !important;
    }

    .apps .arrow-btn{
        display: none !important;
    }

    .apps .slide{
        display: block;
    }
}

@media (min-width:499px) and (max-width: 700px){
    .apps{
        width: calc(100vw - 16vw) !important;
    }
}

@media (max-width: 700px){
    .apps{
        padding: 0 5vw;
    }

    .apps .app-card{
        align-self: center;
        max-height: 100%;
    }

    .apps .apps-index{
        display: none;
    }
}

@-webkit-keyframes swipe-left{
    from{
        transform: translateX(-50vw) skewX(-10deg);
        opacity: 0;
    }
    to{
        transform: translateX(0) skewX(0);
        opacity: 1;
    }
}

@keyframes swipe-left{
    from{
        transform: translateX(-50vw) skewX(-10deg);
        opacity: 0;
    }
    to{
        transform: translateX(0) skewX(0);
        opacity: 1;
    }
}

@media (min-width: 700px) and (max-width: 1000px){
    .apps{
        padding: 0 max(50px, 5vw);
    }

    .apps .app-card{
        width: 100vw;
    }
}

@-webkit-keyframes swipe-right{
    from{
        transform: translateX(50vw) skewX(10deg);
        opacity: 0;
    }
    to{
        transform: translateX(0) skewX(0);
        opacity: 1;
    }
}

@keyframes swipe-right{
    from{
        transform: translateX(50vw) skewX(10deg);
        opacity: 0;
    }
    to{
        transform: translateX(0) skewX(0);
        opacity: 1;
    }
}
.jobs{
    position: relative;
    padding-top: 45px;

    z-index: 100000 !important;
    /* width: 100vw;
    height: 100vh; */
}

.jobs .videos{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    grid-gap: 15px;

    gap: 15px;
    height: 65vh;
}
.app-details{
    display: flex;
    align-items: center;

    height: 95%;
    max-height: 1000px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    padding: 45px 120px 70px 0;
    box-sizing: border-box;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.app-details .info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;

    margin-right: 5vw;
    min-width: 25vw;

    transform: translateY(-5%);
}

.app-details.touch .info{
    transform: translateY(-10%);
}

.app-details .info .tools{
    display: flex;
    flex-wrap: wrap;

    font-size: 2.25vw;
    margin-bottom: 1.5vh;

    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out forwards;
            animation: show-bottom 0.5s ease-out forwards;
}

.app-details .info .tools .tool-icon{
    line-height: 2.25vw;
    letter-spacing: -0.1vw;
    text-transform: capitalize;
}

.app-details .info .name{
    margin: 0;

    font-size: max(6em, 8vw);
    font-weight: 600;
    letter-spacing: -0.5vw;
    line-height: min(5em, 7vw);

    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out 0.15s forwards;
            animation: show-bottom 0.5s ease-out 0.15s forwards;
}

.app-details .info .desc{
    text-align: justify;

    max-width: 25vw;
    font-size: 1.3vw;

    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out 0.25s forwards;
            animation: show-bottom 0.5s ease-out 0.25s forwards;
}

.app-details .info .links{
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out 0.35s forwards;
            animation: show-bottom 0.5s ease-out 0.35s forwards;
}

.app-details .info .link-btn{
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    color: var(--black);
    background-color: transparent;
    border: 1px solid var(--black);
    border-radius: 3px;
    padding: 10px 15px;
}

.app-details .info .link-btn:focus{
    outline: none;
    opacity: 0.5;
}

.app-details .content{
    display: flex;

    margin-right: 5vw;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.app-details .content .highlight{
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 30vw;
    margin-top: -1.5%;
    
    opacity: 0;
}

.app-details .content .highlight img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.app-details .content .highlight:nth-child(odd){
    -webkit-animation: show-top 0.5s ease-out forwards;
            animation: show-top 0.5s ease-out forwards;
}

.app-details .content .highlight:nth-child(even){
    -webkit-animation: show-bottom 0.5s ease-out forwards;
            animation: show-bottom 0.5s ease-out forwards;
}

.app-details .content .highlight img{
    max-height: 100%;
}

.app-details .content .topic{
    display: flex;
    flex-flow: column wrap;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;

    text-align: left;

    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.app-details .content .topic-content{
    width: 30vw !important;
    overflow: hidden;
    padding-bottom: 0.5em;

    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out forwards;
            animation: show-bottom 0.5s ease-out forwards;
}

.app-details .content .topic .question{
    text-transform: lowercase;
    font-size: 2.5vw;
    line-height: 3vw;
    margin: 0;
    margin-bottom: 0.1em;
}

.app-details .content .topic p{
    text-align: justify;
    margin: 0;
}

.app-details .galery{
    position: relative;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-left: 7vw;

    height: 100%; 
}

.app-details .galery .title, .app-details .galery .hollow-title{
    position: absolute;
    top: -5px;
    left: 0;

    font-size: 6vw;
    line-height: 6vw;
    font-weight: 600;
    letter-spacing: -0.5vw;

    margin: 0;

    z-index: 100;
}

.app-details .galery .title{
    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out forwards;
            animation: show-bottom 0.5s ease-out forwards;
}

.app-details .galery .hollow-title{
    top: 4vw;

    color: transparent;
    -webkit-text-stroke: 1px var(--black);

    z-index: 1;

    opacity: 0;
    -webkit-animation: show-hollow 0.5s ease-out 0.5s forwards;
            animation: show-hollow 0.5s ease-out 0.5s forwards;
}

.app-details .galery .image-grid{
    position: relative;

    margin-top: 7%;

    z-index: 5;
}

.app-details.touch .galery .image-grid{
    margin-top: 0;
}

.image-grid.mock .square, .image-grid.mock .panorama, .image-grid.mock .portrait{
    background-color: rgb(150, 150, 150)
}

@media (max-width: 500px){
    .app-details{
        padding: 60px 50px 70px 0;
    }

    .app-details .info .name{
        margin: 0.25em 0 0.2em 0;
        font-size: max(4em, 8vw);
    }

    .app-details .info .desc{
        font-size: 0.9em;
        max-width: 90%;
    }

    .app-details .content .topic{
        display: flex;
        flex-flow: column wrap;
        grid-column-gap: 2vw;
        -webkit-column-gap: 2vw;
                column-gap: 2vw;
    
        text-align: left;
    
        height: 100%;
    }

    .app-details .galery{
        padding-left: 20vw;
    }
}

@media (min-width: 500px) and (max-width: 800px){
    .app-details{
        padding: 60px 100px 70px 15px;
    }

    .app-details .info .name{
        margin: 0.25em 0 0.2em 0;
    }

    .app-details .info .desc{
        font-size: max(0.85em, 1.8vw);
    }
}

@media (max-width: 800px){
    .app-details .content{
        height: 80vh;
    }

    .app-details .info{
        transform: none;
        min-width: 300px;
    }

    .app-details .info .tools{
        font-size: max(1.5em, 3vw);
    }

    .app-details .info .desc{
        max-width: 90%;
    }

    .app-details .content .topic-content{
        width: 85vw !important;
        padding-bottom: 0.5em;
    }

    .app-details .content .highlight{
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin: 0 30px;
        background-color: grey;
    }
    
    .app-details .content .topic .question{
        font-size: 1.5em;
        line-height: 1.5em;
    }

    .app-details .galery .title, .app-details .galery .hollow-title{
        font-size: 3.5em;
        line-height: 0.5em;
        font-weight: 600;
        letter-spacing: -0.5vw;
    }
}

@-webkit-keyframes show-hollow{
    from{
        transform: translateY(-50%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes show-hollow{
    from{
        transform: translateY(-50%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}
.skill-card{
    font-family: "Poppins";

    display: block;
    position: relative;

    -webkit-animation: pop-up 0.5s ease-out;

            animation: pop-up 0.5s ease-out;
    cursor: pointer;

    transition: opacity 0.5s ease-out;
}

.skill-card:hover{
    opacity: 1 !important;
}

.skill-icon, .skill-text{
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    transform: translate(-50%, -50%);

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
    opacity: 1;

    transition: all 0.5s ease-out 0.2s;
}

.skill-percentage{
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
    font-size: 0.5em;
    letter-spacing: -1px;
    font-weight: 500;
    opacity: 0;

    transition: all 0.5s ease-out;
}

.skill-card:hover .skill-icon, .skill-card:hover .skill-text{
    transition-delay: 0;
    opacity: 0;
}

.skill-card:hover .skill-percentage{
    transition-delay: 0.2s;
    opacity: 1;
}

.skill-text{
    font-family: inherit;

    top: 50%;
    left: 50%;

    font-size: 0.55em;
    font-weight: 500;
    text-transform: uppercase;
}

.skill-icon{
    top: 51%;
    left: 51%;
}

.skill-circle, .skill-circle-inside{
    fill: none;
    transform: rotate(-90deg);
    transform-origin: 50%;

    stroke-linecap: round;
}

.skill-circle-inside{
    animation: cu 1s ease-out reverse;
}

.skill-undercircle{
    fill: transparent;
    stroke: rgb(26, 26, 26);
    stroke-dashoffset: 0;
}

@-webkit-keyframes cu{
    to{
        stroke-dashoffset: 213;
    }
}

@keyframes cu{
    to{
        stroke-dashoffset: 213;
    }
}
.icons-card{
    display: grid;
    grid-template-rows: 5vh auto;

    width: 100%;
    max-height: 70vh;
    align-items: center;
    justify-content: center;

    grid-gap: 15px;

    gap: 15px;
}

.icons-card .card-name{
    position: relative;
    margin: 0;
    margin-bottom: 15px;
    margin-right: 15px;

    color: var(--habilidades);
    text-transform: lowercase;
    font-size: 2.2em;
    font-weight: 500;
    letter-spacing: -1px;
}

.icons-card .card-icons{
    position: relative;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* gap: 15px; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 80%;
    margin: auto;


    z-index: 5;
}

.icons-card .skill-card{
    margin-bottom: 15px;
    margin-right: 15px !important;
}

.icons-card .skill-card:first{
    margin-left: 0px;
}

.icons-card .skill-card .skill-icon, .skills .skill-card .skill-text{
    color: var(--habilidades) !important;
}

.icons-card .skill-card .skill-percentage{
    color: var(--habilidades) !important;
}
.skills .time-information{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 3vh 0 6vh 0;
    font-size: 0.9em;

    color: var(--white);
}

.skills .time-information span{
    text-transform: uppercase;
    font-weight: 500;
}


.skills .time-information .circle{
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 10px;

    background-color: var(--white);

    animation: flicker 2s ease-out alternate-reverse infinite;
}

.skills .cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    grid-gap: 15px;
}

.skills .cards .card-placeholder .icons-card{
    -webkit-animation: show-icons 0.5s ease-out;
            animation: show-icons 0.5s ease-out;
}

@media (max-width: 800px){
    .skills{
        padding-top: 55px;
        height: 90vh !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;

        box-sizing: border-box;
    }
    
    .skills .time-information{
        width: 90vw;
        padding-left: 2vw;
    }

    .skills .time-information span{
        text-transform: uppercase;
        font-weight: 500;

        animation: flicker 2s ease-out alternate-reverse infinite;
    }
    
    
    .skills .time-information .circle{
        display: none;
    }

    .skills .cards{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        grid-gap: 15px;
        padding-right: 10vw;
        margin-left: -3vw;
    }

    .skills .card-placeholder{
        min-width: min(100vw, 400px);
    }
}

@media (min-width: 500px) and (max-width: 800px){
    .skills .time-information span{
        text-transform: uppercase;
        font-weight: 500;
        margin-left: -9vw;
        max-width: 90vw;

        animation: flicker 2s ease-out alternate-reverse infinite;
    }
}

@media (min-width: 800px){
    .skills{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        height: 85%;
    }
}

@-webkit-keyframes show-icons{
    from{
        transform: translateY(100px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes show-icons{
    from{
        transform: translateY(100px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes flicker{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes flicker{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

/* @keyframes drop-letter{
    from{
        transform: translateY(-250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}

@keyframes slide-letter{
    from{
        transform: translateY(250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
} */
.menu-icon{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    font-size: 60px;
    width: 1em;
    height: 0.9em;

    cursor: pointer;
}

.menu-icon div{
    background-color: var(--black);
    width: 100%;
    height: 13%;
    border-radius: 15px;

    transition: all 0.5s ease-out;
}

.menu-icon .top{
    transform-origin: top left;
}

.menu-icon.clicked .top{
    transform: rotate(46deg) scaleX(1.2) translate(0.15em, -0.15em);
}

.menu-icon.clicked .middle{
    transition: opacity 0.25s ease-out;
    opacity: 0;
}

.menu-icon .bottom{
    transform-origin: bottom left;
}

.menu-icon.clicked .bottom{
    transform: rotate(-46deg) scaleX(1.2) translate(0.1em, 0.22em);
}

.menu-icon:hover div, .menu-icon.clicked:hover div{
    background-color: var(--main-color) !important;
}
.menu{
    position: absolute;
    top: 0;
    left: 0;

    width: 35px;
    height: 35px;

    background-color: transparent;
    z-index: 100;
}

.menu .menu-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);

    width: max(800px, max(70vw, 70vh));
    height: max(800px, max(70vw, 70vh));
    max-width: 1200px;
    max-height: 1200px;
    background-color: var(--white);
    border-radius: 50%;

    transition: transform 0.6s ease;
}

.menu.showing .menu-container{
    transform: translate(-50%, -50%) scale(1);
}

.menu nav{
    position: absolute;
    top: 125% !important;
    right: 0 !important;

    color: var(--black);
    text-transform: lowercase;
    font-size: 2em;
    font-weight: 500;

    transform: scale(0);
}

.menu.showing nav{
    transform: scale(1);
}

.menu nav ul{
    list-style-type: none;
    text-align: right;

    margin: 0;
}

.menu nav ul li{
    cursor: pointer;
    transform: translateX(50px);
    opacity: 0;
    line-height: 1.5em;
    white-space: unset;

    transition: all ease-out;
    transition-delay: 0ms;
    transition-duration: 1ms;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;

    width: 200px;
}

.menu.showing nav ul li{
    transform: translateX(0px);
    opacity: 1;
    transition-duration: 500ms;
}

.menu.showing nav ul li:hover{
    color: var(--highlight-color) !important;
    transition-duration: 0.25s;
    transition-delay: 0s !important;
}

.menu.showing nav ul li:nth-child(1){
    transition-delay: 150ms;
}

.menu.showing nav ul li:nth-child(2){
    transition-delay: 300ms;
}

.menu.showing nav ul li:nth-child(3){
    transition-delay: 450ms;
}

.menu.showing nav ul li:nth-child(4){
    transition-delay: 600ms;
}

.menu.showing nav ul li:nth-child(5){
    transition-delay: 750ms;
}

@media (max-width: 500px){
    .menu{
        right: -55vh;
    }
}

@media (max-width: 1000px){
    .menu nav{
        color: var(--black);
        text-transform: lowercase;
        font-size: min(2em, 5vh);
        font-weight: 500;
    }
}

@-webkit-keyframes grow{
    from{
        transform: scale(0);
    }
}

@keyframes grow{
    from{
        transform: scale(0);
    }
}
.navbar{
    position: fixed;
    left: 0;
    right: 0;
    padding: 0 min(60px, 10vw);

    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 1000;
}

.navbar .right{
    position: relative !important;
}

.navbar .link:focus{
    outline: 0;
}

.navbar .logo{
    width: 40px;
    z-index: 150;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.navbar .menu-icon{
    position: relative;

    font-size: 35px;
    transition: background-color 0.5 ease-out;

    z-index: 150;
}

.navbar .menu-icon.clicked div{
    background-color: var(--black) !important;
}

.navbar.dark .logo{
    fill: var(--black) !important;
}

.navbar.dark .menu-icon div{
    background-color: var(--black) !important;
}

.navbar.dark .menu-icon.clicked div{
    background-color: var(--white) !important;
}

.navbar.dark .menu .menu-container{
    background-color: var(--black);
}

.navbar.dark .menu nav{
    color: var(--white);
}

@media (max-width:500px){
    .navbar{
        padding: 0 min(30px, 8vw);
    }
}
.glitch-text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    font-weight: 700;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.glitch-text .repetitions{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.glitch-text span{
    font-size: 3em;
    font-weight: inherit;
    letter-spacing: -0.05em;
    line-height: 0.7em;

    margin: 0;
    padding: 0;
}

.glitch-text .auxiliar-text{
    color: transparent;
    -webkit-text-stroke: 1px black;
    opacity: 0;

    -webkit-animation: drop 1s ease-out forwards;

            animation: drop 1s ease-out forwards;
}

@-webkit-keyframes drop{
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    30%{
        opacity: 1;
        transform: translateY(0px);
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

@keyframes drop{
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    30%{
        opacity: 1;
        transform: translateY(0px);
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.blurry-text{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: transparent;
    color: var(--black);

    -webkit-filter: blur(20px);

            filter: blur(20px);
    
    width: 100%;
    height: 100%;

    padding: 0 min(60px, 10vw);
    box-sizing: border-box;
    /* background-color: violet; */

    overflow: hidden;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    z-index: -1 !important;
}

.blurry-text .letter{
    position: relative;
    opacity: 0.1;
    text-transform: uppercase;
    font-size: 33vh;
    font-weight: 500;

    -webkit-animation-timing-function: ease-out;

            animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

@media (max-width: 1000px){
    .blurry-text .letter{
        opacity: 0.2;
    }
}

@-webkit-keyframes drop-letter{
    from{
        transform: translateY(-250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}

@keyframes drop-letter{
    from{
        transform: translateY(-250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}

@-webkit-keyframes slide-letter{
    from{
        transform: translateY(250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}

@keyframes slide-letter{
    from{
        transform: translateY(250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}
.pages-selector{
    position: absolute;
    bottom: min(40px, 10vw);
    left: 50%;
    transform: translateX(-50%);

    display: flex;

    z-index: 1000;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.pages-selector *:not(:last-child){
    margin-right: 20px;
}

.pages-selector label{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: max(15px, 1.25vw);
    height: max(15px, 1.25vw);
    background-color: transparent;

    border: 3px solid var(--white);
    border-radius: 50%;

    cursor: pointer;
}

.pages-selector label input{
    position: absolute;
    opacity: 0;
    width: 0;
}

.pages-selector label .page-bubble{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: max(20px, 1.5vw);
    height: max(20px, 1.5vw);
    border-radius: 50%;
    background-color: transparent;

    transition: all 0.5s ease-out;
}

.pages-selector label input:checked+.page-bubble{
    background-color: var(--white);
}

.pages-selector label .page-name{
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);

    color: var(--white);
    font-size: 1.4em;
    text-transform: lowercase;

    opacity: 0;

    transition: all 0.5s ease-out;
}

.pages-selector label:hover .page-name{
    transform: translate(-50%, -30%);
    opacity: 1;
}
.simple-info{
    color: var(--white);
    text-align: left;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
}

.simple-info .title{
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;

    font-size: 1.2em;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}

.simple-info .children{
    font-size: 1.5em;
}
.contact{
    height: calc(100vh - min(120px, 20vw) - 55px) !important;
    width: calc(100vw - min(120px, 20vw)) !important;

    padding-top: 55px;
    max-height: 700px;
}

.contact .animated-text{
    color: var(--white);

    font-size: 5.5vw;
    line-height: 5.5vw;
    font-weight: 600;
    letter-spacing: -0.2vw;
}

.contact .headline{
    height: 11vw;
}

.contact .left-container{
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    align-items: center;

    height: 100%;
}

.contact .left-container .top{
    padding: 5vh 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.contact .left-container .bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
    grid-row-gap: 25px;
    row-gap: 25px;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.contact .left-container .social{
    display: flex;
    margin-top: 5px;
}

.contact .left-container .social .social-icon{
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--white);
    font-size: 1.4em;
    text-decoration: none;
}

.contact .left-container .social-icon *{
    transition: all 0.3s ease-out;
}

.contact .left-container .social-icon:hover *{
    color: var(--contato);
}

.contact .resume-btn{
    position: relative;

    font-family: inherit;
    border: 1px solid var(--white);
    border-radius: 5px;
    color: var(--white);

    background-color: transparent;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;

    margin-top: 5px;

    transition: all 0.5s ease-out;

    cursor: pointer;
}

.contact .resume-btn:hover{
    color: var(--black);
}

.contact .resume-btn::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 0%;
    height: 100%;
    background-color: var(--white);

    transition: all 0.5s ease-out;

    z-index: -1;
}

.contact .resume-btn:hover::after{
    width: 100%;
}

.contact .resume-btn a{
    font-family: inherit;
    text-decoration: none;
    color: inherit;
}

.contact .bottom .simple-info{
    opacity: 0;
    -webkit-animation: show-bottom 0.5s ease-out forwards;
            animation: show-bottom 0.5s ease-out forwards;
}

.contact .bottom .email{
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}

.contact .bottom .phone{
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
}

.contact .bottom .social-container{
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.contact .bottom .resume{
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s;
}

.contact .lottie{
    position: absolute;
    top: 5%;
    right: 3%;
    width: 70vh;
    height: 70vh;

    max-width: 1000px;
    max-height: 1000px;
}

@media (max-width: 500px){
    .contact{
        width: 92vw !important;
        padding-left: 15px;
    }

    .contact .headline{
        height: 23vw !important;
    }

    .contact .animated-text{
        font-size: min(3.2em, 10vw);
        line-height: 11.5vw;
    }

    .contact .left-container .bottom{
        display: flex;
        flex-direction: column;

        font-size: 0.8em;
        margin-top: -50px;
    
        width: -webkit-fit-content;
    
        width: -moz-fit-content;
    
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}

@media (min-width: 500px) and (max-width: 720px){
    .contact{
        width: 84vw !important;
    }

    .contact .headline{
        height: 23vw !important;
    }

    .contact .animated-text{
        color: var(--white);
    
        font-size: min(3.5em, 10vw);
        line-height: 11vw;
    }

    .contact .bottom .email .children, .contact .bottom .phone .children{
        font-size: 1.1em;
    }

    .contact .bottom .phone .children{
        white-space: nowrap;
    }
}

@media (max-width: 720px){
    .contact{
        margin-top: -30px;    
    }

    .contact .left-container{
        grid-template-rows: 40% 60%;
    }

    .contact .left-container .bottom{
        grid-column-gap: 0;
        -webkit-column-gap: 0;
                column-gap: 0;
        grid-row-gap: 0;
        row-gap: 0;
    }

    .contact .headline{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        max-width: 90vw;
    }

    .contact .bottom .simple-info:not(:last-child){
        margin-bottom: 15px;
    }
}

@media (max-width: 950px){
    .contact .lottie{
        display: none;
    }
}

@media (min-width: 950px) and (max-width: 1100px){
    .contact .lottie{
        top: 10%;
        right: 0;
        width: 35vw;
        height: 35vw;
    }
}
html, body{
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* scrollbar-width: thin !important;
  scrollbar-color: var(--sobre) var(--black); */
}

::-webkit-scrollbar {
  display: none;
}

::selection{
  color: var(--black);
  background-color: var(--white);
}

.App {
  position: relative;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;

  box-sizing: border-box;

  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.image-focus{
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 0px;

  background-color: transparent;

  z-index: 2000;

  transition: background-color 0.5s ease-out;
}

.image-focus.showing{
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.7);
}

.image-focus ~ .scrollable-container{
  transition: all 0.5s ease-out;
}

.image-focus.showing ~ .scrollable-container{
  -webkit-filter: blur(8px);
          filter: blur(8px);
}

.image-focus button{
  display: none;
}

.image-focus img{
  position: absolute;
}

.image-focus.showing img{
  -webkit-animation: zoom-img 0.5s ease-out forwards;
          animation: zoom-img 0.5s ease-out forwards;
}

.image-focus.hidden img{
  -webkit-animation: unzoom-img 0.5s ease-out forwards;
          animation: unzoom-img 0.5s ease-out forwards;
}

.scrollable-container{
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  scrollbar-color: #000000 #C2D2E4;
}

.App .navbar .logo{
  fill: var(--white);
}

.App .navbar .menu-icon div{
  background-color: var(--white);
}

.App .glitch-text{
  position: fixed;

  z-index: 500;
}

.App .page.dark{
  background-color: var(--white);
}

.btn{
  position: relative;
  
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;

  font-size: 0.9em;
  color: var(--black);
  background-color: transparent;
  border: 1px solid var(--black);
  border-radius: 3px;

  padding: 10px 15px;

  transition: all 0.5s ease-out;
}

.btn:hover{
  color: var(--white);
}

.btn::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 0;
  height: 100%;
  background-color: var(--black);

  transition: all 0.4s ease-out;

  z-index: -1;
}

.btn:hover::after{
  width: 100%;
}

.page-arrow{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  z-index: 500;
}

.spaced-items > *:not(:last-child){
  margin-right: 10px;
}

.spaced-items-vertical > *:not(:last-child){
  margin-bottom: 10px;
}

@-webkit-keyframes show-zoom{
  from{
    height: 0;
  }
  to{
    height: 100vh;
  }
}

@keyframes show-zoom{
  from{
    height: 0;
  }
  to{
    height: 100vh;
  }
}

@-webkit-keyframes zoom-img{
  from{
  }
  to{
    transform: scale(1.5);
  }
}

@keyframes zoom-img{
  from{
  }
  to{
    transform: scale(1.5);
  }
}

@-webkit-keyframes unzoom-img{
  from{
  }
  to{
    top: auto;
    left: auto;
    transform: translate(0,0);
  }
}

@keyframes unzoom-img{
  from{
  }
  to{
    top: auto;
    left: auto;
    transform: translate(0,0);
  }
}

@-webkit-keyframes show-left{
  from{
    transform: translateX(150px);
    opacity: 0;
  }
  to{
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes show-left{
  from{
    transform: translateX(150px);
    opacity: 0;
  }
  to{
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes show-right{
  from{
    transform: translateX(-150px);
    opacity: 0;
  }
  to{
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes show-right{
  from{
    transform: translateX(-150px);
    opacity: 0;
  }
  to{
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes show-bottom{
  from{
    transform: translateY(150px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes show-bottom{
  from{
    transform: translateY(150px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes show-top{
  from{
    transform: translateY(-150px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes show-top{
  from{
    transform: translateY(-150px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}
.glitch-text{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    font-size: 3.5vw;

    -webkit-animation: shrink 0.6s ease-out 1.5s forwards;

            animation: shrink 0.6s ease-out 1.5s forwards;

    color: var(--black);
}

.glitch-text.quick{
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-duration: 0ms;
            animation-duration: 0ms;
}

.glitch-text.quick .repetitions{
    display: none;
}

.glitch-text .auxiliar-text{
    -webkit-text-stroke: 1px var(--black);
}

@media (max-width: 449px){
    .glitch-text{
        font-size: 4.5vw;
        -webkit-animation: small-shrink 0.6s ease-out 1.5s forwards !important;
                animation: small-shrink 0.6s ease-out 1.5s forwards !important;
    }

    .glitch-text span{
        line-height: 0.9em;
    }
}

@-webkit-keyframes shrink{
    to{
        top: calc(min(65px, 10vw) + 10px);
        right: calc(min(65px, 10vw) + 50px);
        transform: translate(0,0);

        font-size: 0.75em;
        font-weight: 600;
    }
}

@keyframes shrink{
    to{
        top: calc(min(65px, 10vw) + 10px);
        right: calc(min(65px, 10vw) + 50px);
        transform: translate(0,0);

        font-size: 0.75em;
        font-weight: 600;
    }
}

@-webkit-keyframes small-shrink{
    to{
        top: calc(min(65px, 10vw) + 15px);
        right: calc(min(65px, 10vw) + 40px);
        transform: translate(0,0);

        font-size: 3vw;
        font-weight: 600;
    }
}

@keyframes small-shrink{
    to{
        top: calc(min(65px, 10vw) + 15px);
        right: calc(min(65px, 10vw) + 40px);
        transform: translate(0,0);

        font-size: 3vw;
        font-weight: 600;
    }
}
