.contact{
    height: calc(100vh - min(120px, 20vw) - 55px) !important;
    width: calc(100vw - min(120px, 20vw)) !important;

    padding-top: 55px;
    max-height: 700px;
}

.contact .animated-text{
    color: var(--white);

    font-size: 5.5vw;
    line-height: 5.5vw;
    font-weight: 600;
    letter-spacing: -0.2vw;
}

.contact .headline{
    height: 11vw;
}

.contact .left-container{
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    align-items: center;

    height: 100%;
}

.contact .left-container .top{
    padding: 5vh 0;
    width: fit-content;
}

.contact .left-container .bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 25px;

    width: fit-content;
    height: fit-content;
}

.contact .left-container .social{
    display: flex;
    margin-top: 5px;
}

.contact .left-container .social .social-icon{
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--white);
    font-size: 1.4em;
    text-decoration: none;
}

.contact .left-container .social-icon *{
    transition: all 0.3s ease-out;
}

.contact .left-container .social-icon:hover *{
    color: var(--contato);
}

.contact .resume-btn{
    position: relative;

    font-family: inherit;
    border: 1px solid var(--white);
    border-radius: 5px;
    color: var(--white);

    background-color: transparent;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;

    margin-top: 5px;

    transition: all 0.5s ease-out;

    cursor: pointer;
}

.contact .resume-btn:hover{
    color: var(--black);
}

.contact .resume-btn::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 0%;
    height: 100%;
    background-color: var(--white);

    transition: all 0.5s ease-out;

    z-index: -1;
}

.contact .resume-btn:hover::after{
    width: 100%;
}

.contact .resume-btn a{
    font-family: inherit;
    text-decoration: none;
    color: inherit;
}

.contact .bottom .simple-info{
    opacity: 0;
    animation: show-bottom 0.5s ease-out forwards;
}

.contact .bottom .email{
    animation-delay: 0.8s;
}

.contact .bottom .phone{
    animation-delay: 0.9s;
}

.contact .bottom .social-container{
    animation-delay: 1s;
}

.contact .bottom .resume{
    animation-delay: 1.1s;
}

.contact .lottie{
    position: absolute;
    top: 5%;
    right: 3%;
    width: 70vh;
    height: 70vh;

    max-width: 1000px;
    max-height: 1000px;
}

@media (max-width: 500px){
    .contact{
        width: 92vw !important;
        padding-left: 15px;
    }

    .contact .headline{
        height: 23vw !important;
    }

    .contact .animated-text{
        font-size: min(3.2em, 10vw);
        line-height: 11.5vw;
    }

    .contact .left-container .bottom{
        display: flex;
        flex-direction: column;

        font-size: 0.8em;
        margin-top: -50px;
    
        width: fit-content;
        height: fit-content;
    }
}

@media (min-width: 500px) and (max-width: 720px){
    .contact{
        width: 84vw !important;
    }

    .contact .headline{
        height: 23vw !important;
    }

    .contact .animated-text{
        color: var(--white);
    
        font-size: min(3.5em, 10vw);
        line-height: 11vw;
    }

    .contact .bottom .email .children, .contact .bottom .phone .children{
        font-size: 1.1em;
    }

    .contact .bottom .phone .children{
        white-space: nowrap;
    }
}

@media (max-width: 720px){
    .contact{
        margin-top: -30px;    
    }

    .contact .left-container{
        grid-template-rows: 40% 60%;
    }

    .contact .left-container .bottom{
        column-gap: 0;
        row-gap: 0;
    }

    .contact .headline{
        height: fit-content;
        max-width: 90vw;
    }

    .contact .bottom .simple-info:not(:last-child){
        margin-bottom: 15px;
    }
}

@media (max-width: 950px){
    .contact .lottie{
        display: none;
    }
}

@media (min-width: 950px) and (max-width: 1100px){
    .contact .lottie{
        top: 10%;
        right: 0;
        width: 35vw;
        height: 35vw;
    }
}