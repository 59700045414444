.menu{
    position: absolute;
    top: 0;
    left: 0;

    width: 35px;
    height: 35px;

    background-color: transparent;
    z-index: 100;
}

.menu .menu-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);

    width: max(800px, max(70vw, 70vh));
    height: max(800px, max(70vw, 70vh));
    max-width: 1200px;
    max-height: 1200px;
    background-color: var(--white);
    border-radius: 50%;

    transition: transform 0.6s ease;
}

.menu.showing .menu-container{
    transform: translate(-50%, -50%) scale(1);
}

.menu nav{
    position: absolute;
    top: 125% !important;
    right: 0 !important;

    color: var(--black);
    text-transform: lowercase;
    font-size: 2em;
    font-weight: 500;

    transform: scale(0);
}

.menu.showing nav{
    transform: scale(1);
}

.menu nav ul{
    list-style-type: none;
    text-align: right;

    margin: 0;
}

.menu nav ul li{
    cursor: pointer;
    transform: translateX(50px);
    opacity: 0;
    line-height: 1.5em;
    white-space: unset;

    transition: all ease-out;
    transition-delay: 0ms;
    transition-duration: 1ms;

    user-select: none;

    width: 200px;
}

.menu.showing nav ul li{
    transform: translateX(0px);
    opacity: 1;
    transition-duration: 500ms;
}

.menu.showing nav ul li:hover{
    color: var(--highlight-color) !important;
    transition-duration: 0.25s;
    transition-delay: 0s !important;
}

.menu.showing nav ul li:nth-child(1){
    transition-delay: 150ms;
}

.menu.showing nav ul li:nth-child(2){
    transition-delay: 300ms;
}

.menu.showing nav ul li:nth-child(3){
    transition-delay: 450ms;
}

.menu.showing nav ul li:nth-child(4){
    transition-delay: 600ms;
}

.menu.showing nav ul li:nth-child(5){
    transition-delay: 750ms;
}

@media (max-width: 500px){
    .menu{
        right: -55vh;
    }
}

@media (max-width: 1000px){
    .menu nav{
        color: var(--black);
        text-transform: lowercase;
        font-size: min(2em, 5vh);
        font-weight: 500;
    }
}

@keyframes grow{
    from{
        transform: scale(0);
    }
}