.app-details{
    display: flex;
    align-items: center;

    height: 95%;
    max-height: 1000px;
    width: fit-content;

    padding: 45px 120px 70px 0;
    box-sizing: border-box;

    user-select: none;
}

.app-details .info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    text-align: left;
    width: fit-content;
    height: 100%;

    margin-right: 5vw;
    min-width: 25vw;

    transform: translateY(-5%);
}

.app-details.touch .info{
    transform: translateY(-10%);
}

.app-details .info .tools{
    display: flex;
    flex-wrap: wrap;

    font-size: 2.25vw;
    margin-bottom: 1.5vh;

    opacity: 0;
    animation: show-bottom 0.5s ease-out forwards;
}

.app-details .info .tools .tool-icon{
    line-height: 2.25vw;
    letter-spacing: -0.1vw;
    text-transform: capitalize;
}

.app-details .info .name{
    margin: 0;

    font-size: max(6em, 8vw);
    font-weight: 600;
    letter-spacing: -0.5vw;
    line-height: min(5em, 7vw);

    opacity: 0;
    animation: show-bottom 0.5s ease-out 0.15s forwards;
}

.app-details .info .desc{
    text-align: justify;

    max-width: 25vw;
    font-size: 1.3vw;

    opacity: 0;
    animation: show-bottom 0.5s ease-out 0.25s forwards;
}

.app-details .info .links{
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    opacity: 0;
    animation: show-bottom 0.5s ease-out 0.35s forwards;
}

.app-details .info .link-btn{
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    color: var(--black);
    background-color: transparent;
    border: 1px solid var(--black);
    border-radius: 3px;
    padding: 10px 15px;
}

.app-details .info .link-btn:focus{
    outline: none;
    opacity: 0.5;
}

.app-details .content{
    display: flex;

    margin-right: 5vw;
    height: 100%;
    width: fit-content;
}

.app-details .content .highlight{
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 30vw;
    margin-top: -1.5%;
    
    opacity: 0;
}

.app-details .content .highlight img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.app-details .content .highlight:nth-child(odd){
    animation: show-top 0.5s ease-out forwards;
}

.app-details .content .highlight:nth-child(even){
    animation: show-bottom 0.5s ease-out forwards;
}

.app-details .content .highlight img{
    max-height: 100%;
}

.app-details .content .topic{
    display: flex;
    flex-flow: column wrap;
    column-gap: 40px;

    text-align: left;

    height: 100%;
    width: fit-content;
}

.app-details .content .topic-content{
    width: 30vw !important;
    overflow: hidden;
    padding-bottom: 0.5em;

    opacity: 0;
    animation: show-bottom 0.5s ease-out forwards;
}

.app-details .content .topic .question{
    text-transform: lowercase;
    font-size: 2.5vw;
    line-height: 3vw;
    margin: 0;
    margin-bottom: 0.1em;
}

.app-details .content .topic p{
    text-align: justify;
    margin: 0;
}

.app-details .galery{
    position: relative;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-left: 7vw;

    height: 100%; 
}

.app-details .galery .title, .app-details .galery .hollow-title{
    position: absolute;
    top: -5px;
    left: 0;

    font-size: 6vw;
    line-height: 6vw;
    font-weight: 600;
    letter-spacing: -0.5vw;

    margin: 0;

    z-index: 100;
}

.app-details .galery .title{
    opacity: 0;
    animation: show-bottom 0.5s ease-out forwards;
}

.app-details .galery .hollow-title{
    top: 4vw;

    color: transparent;
    -webkit-text-stroke: 1px var(--black);

    z-index: 1;

    opacity: 0;
    animation: show-hollow 0.5s ease-out 0.5s forwards;
}

.app-details .galery .image-grid{
    position: relative;

    margin-top: 7%;

    z-index: 5;
}

.app-details.touch .galery .image-grid{
    margin-top: 0;
}

.image-grid.mock .square, .image-grid.mock .panorama, .image-grid.mock .portrait{
    background-color: rgb(150, 150, 150)
}

@media (max-width: 500px){
    .app-details{
        padding: 60px 50px 70px 0;
    }

    .app-details .info .name{
        margin: 0.25em 0 0.2em 0;
        font-size: max(4em, 8vw);
    }

    .app-details .info .desc{
        font-size: 0.9em;
        max-width: 90%;
    }

    .app-details .content .topic{
        display: flex;
        flex-flow: column wrap;
        column-gap: 2vw;
    
        text-align: left;
    
        height: 100%;
    }

    .app-details .galery{
        padding-left: 20vw;
    }
}

@media (min-width: 500px) and (max-width: 800px){
    .app-details{
        padding: 60px 100px 70px 15px;
    }

    .app-details .info .name{
        margin: 0.25em 0 0.2em 0;
    }

    .app-details .info .desc{
        font-size: max(0.85em, 1.8vw);
    }
}

@media (max-width: 800px){
    .app-details .content{
        height: 80vh;
    }

    .app-details .info{
        transform: none;
        min-width: 300px;
    }

    .app-details .info .tools{
        font-size: max(1.5em, 3vw);
    }

    .app-details .info .desc{
        max-width: 90%;
    }

    .app-details .content .topic-content{
        width: 85vw !important;
        padding-bottom: 0.5em;
    }

    .app-details .content .highlight{
        width: max-content;
        margin: 0 30px;
        background-color: grey;
    }
    
    .app-details .content .topic .question{
        font-size: 1.5em;
        line-height: 1.5em;
    }

    .app-details .galery .title, .app-details .galery .hollow-title{
        font-size: 3.5em;
        line-height: 0.5em;
        font-weight: 600;
        letter-spacing: -0.5vw;
    }
}

@keyframes show-hollow{
    from{
        transform: translateY(-50%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}