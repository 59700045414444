.skills .time-information{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 3vh 0 6vh 0;
    font-size: 0.9em;

    color: var(--white);
}

.skills .time-information span{
    text-transform: uppercase;
    font-weight: 500;
}


.skills .time-information .circle{
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 10px;

    background-color: var(--white);

    animation: flicker 2s ease-out alternate-reverse infinite;
}

.skills .cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    grid-gap: 15px;
}

.skills .cards .card-placeholder .icons-card{
    animation: show-icons 0.5s ease-out;
}

@media (max-width: 800px){
    .skills{
        padding-top: 55px;
        height: 90vh !important;
        width: fit-content;

        box-sizing: border-box;
    }
    
    .skills .time-information{
        width: 90vw;
        padding-left: 2vw;
    }

    .skills .time-information span{
        text-transform: uppercase;
        font-weight: 500;

        animation: flicker 2s ease-out alternate-reverse infinite;
    }
    
    
    .skills .time-information .circle{
        display: none;
    }

    .skills .cards{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        grid-gap: 15px;
        padding-right: 10vw;
        margin-left: -3vw;
    }

    .skills .card-placeholder{
        min-width: min(100vw, 400px);
    }
}

@media (min-width: 500px) and (max-width: 800px){
    .skills .time-information span{
        text-transform: uppercase;
        font-weight: 500;
        margin-left: -9vw;
        max-width: 90vw;

        animation: flicker 2s ease-out alternate-reverse infinite;
    }
}

@media (min-width: 800px){
    .skills{
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        height: 85%;
    }
}

@keyframes show-icons{
    from{
        transform: translateY(100px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes flicker{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

/* @keyframes drop-letter{
    from{
        transform: translateY(-250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}

@keyframes slide-letter{
    from{
        transform: translateY(250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
} */