.image img{
    position: absolute;
    animation: set-img 0.5s ease-out forwards;
}

.designs{
    position: relative;
    z-index: 1000;
    width: fit-content;
}

.designs .image-grid{
    margin-right: 15px !important;
}

@keyframes set-img{
    to{
        top: 50%;
        left: 50%;
        transform: translate(-50% -50%);
        width: 100vw;
    }
}

@media (max-width: 500px){
    .designs .image-grid{
        padding-left: 15px;
    }
}

@media (min-width: 500px){
    .designs .image-grid{
        padding-right: min(60px, 8vw) !important;
    }
}