.image-grid{
    display: flex;

    height: calc(min(500px, 64vh) + 10px);
}

.image-grid.mock .square, .image-grid.mock .panorama, .image-grid.mock .portrait{
    background-color: rgb(77, 77, 77);
    border-radius: 5px;

    animation: flicker 1.5s ease alternate-reverse infinite;
}

.image-grid img{
    position: relative;

    border-radius: 5px;
    object-fit: cover;
    image-rendering: pixelated;
    opacity: 1;

    transition: opacity 0.5s ease-out;
    cursor: pointer;

    animation: show-up 1s ease-out;
}

.image-grid img:hover{
    opacity: 0.5;
}

.image-grid .square{
    width: min(250px, 32vh);
    height: min(250px, 32vh);
}

.image-grid .panorama{
    width: auto;
    height: min(250px, 32vh);
}

.image-grid .portrait{
    width: min(250px, 32vh);
    height: 100%;
}

.image-grid .squares-panorama, .image-grid .squares-panorama-reversed{
    display: flex;
    flex-direction: column;
}

.image-grid .squares-panorama-reversed{
    flex-direction: column-reverse;
}

.image-grid .squares{
    display: flex;
    flex-direction: row;
}

.image-grid .two-squares{
    display: flex;
    flex-direction: column;
}

.image-grid .bigger-square img{
    width: min(500px, 64vh);
    height: 100%;
}

.image-grid .two-panoramas{
    display: flex;
    flex-direction: column;
}

.image-grid .bigger-panorama img{
    width: min(1000px, 128vh);
    height: 100%;
}

@keyframes flicker{
    from{
        opacity: 0.3;
    }
    to{
        opacity: 1;
    }
}

@keyframes show-up{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}