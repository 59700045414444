.img-following-text{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 25px;
    width: 300px;

    border-top: 1px solid var(--white);
    border-bottom: 1px solid var(--white);
    color: var(--white);

    user-select: none;
    cursor: pointer;
}

.img-following-text::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: violet;
    opacity: 0;

    z-index: 50;
}

.img-following-text span{
    text-transform: lowercase;
    text-align: left;
    font-size: 1.5em;
    font-weight: 600;

    color: var(--white);
    
    z-index: 10;
}

.img-following-text img{
    position: absolute;

    max-width: 50%;
    max-height: 90%;

    z-index: 5;

    opacity: 0;
    transition: all 0.5s ease-out;

    user-select: none;
}

.img-following-text:hover img{
    opacity: 1;
}