.page-arrow{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    background-color: transparent;
    color: var(--white);
    font-size: 50px;

    cursor: pointer;
}

.page-arrow div{
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-arrow span{
    position: relative;
    left: 0;
    transform: translateX(-30%);

    width: fit-content;
    margin-right: 200%;
    height: fit-content;
    font-size: 0.5em;
    font-weight: 600;
    line-height: 1em;
    text-align: right;
    text-transform: lowercase;

    opacity: 0;

    transition: all 0.3s ease-out;
}

.page-arrow.split span{
    transform: none;
    width: 100px;
    margin-right: 125px;
}

.page-arrow:hover span{
    opacity: 1;
    transform: translateX(-40%);
}

.page-arrow.split:hover span{
    opacity: 1;
    margin-right: 150px;
    transform: none;
}