.video-card{
    display: flex;
    align-items: center;

    height: 175px;
    width: 550px;
    background-color: var(--white);
    border-radius: 15px;

    padding: 3px 15px;
}

.video-card a{
    text-decoration: none;
    color: inherit;
}

.video-card .video-media{
    position: relative;

    display: flex;

    width: 300px;
    height: 145px;

    background-color: transparent;
}

.video-card .video-media img{
    object-fit: contain;
    width: 100%;

    border-radius: 10px;

    filter: drop-shadow(0 3px 6px rgba(41, 38, 38, 0.3));

    transition: filter 0.5s ease-out;
}

.video-card .video-media img:hover{
    filter: drop-shadow(0 3px 10px rgba(58, 61, 68, 0.5));
}

.video-card .video-media .duration{
    position: absolute;
    bottom: 10px;
    right: 15px;

    padding: 3px 8px;
    border-radius: 8px;
    color: var(--white);
    font-weight: 500;
    font-size: 0.9em;

    background-color: var(--black);

    user-select: none;
}

.video-card .video-info{
    width: 60%;
}

.video-card .video-info .video-name{
    margin: 0;

    text-align: left;
    font-size: 1.6em;
    font-weight: 600;
    letter-spacing: -0.05em;

    transition: color 0.4s ease-out;
}

.video-card .video-info .video-name:hover{
    color: var(--trabalhos);
}

.video-card .video-info .video-description{
    margin: 0;

    font-size: 0.9em;
    line-height: 1.25em;
    text-align: justify;
}

.video-card .video-info .video-tools{
    margin-top: 10px;

    display: flex;
    font-size: 1.5em;

    color: var(--black);
}

@media (max-width: 1000px){
    .video-card{
        display: grid;
        grid-template-rows: 45% 55%;
    
        height: 100%;
        width: min(90vw, 300px);
        background-color: var(--white);
        border-radius: 15px;
    
        padding: 15px 10px;
        box-sizing: border-box;
    }

    .video-card .video-media{
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;

        width: 100%;
        height: fit-content;
    }

    .video-card .video-media img{
        object-fit: cover;
        margin: 0 auto;
        margin-left: 8px;
    }

    .video-card .video-media .duration{
        bottom: 15px;
        right: 25px;
    }

    .video-card .video-info{
        width: 90%;
        height: 100%;
        margin: 0 auto;
    }

    .video-card .video-info .video-name{
        text-align: center;
        margin-bottom: -20px;
    }
    
    .video-card .video-info .video-description{
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        width: 100%;
    }

    .video-card .video-info .video-tools{
        align-items: center;
        justify-content: center;
    }

}

@media (min-width: 1000px){
    .video-card .video-media{
        margin-right: 15px;
    }
}