.icons-card{
    display: grid;
    grid-template-rows: 5vh auto;

    width: 100%;
    max-height: 70vh;
    align-items: center;
    justify-content: center;

    gap: 15px;
}

.icons-card .card-name{
    position: relative;
    margin: 0;
    margin-bottom: 15px;
    margin-right: 15px;

    color: var(--habilidades);
    text-transform: lowercase;
    font-size: 2.2em;
    font-weight: 500;
    letter-spacing: -1px;
}

.icons-card .card-icons{
    position: relative;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* gap: 15px; */
    width: fit-content;
    max-width: 80%;
    margin: auto;


    z-index: 5;
}

.icons-card .skill-card{
    margin-bottom: 15px;
    margin-right: 15px !important;
}

.icons-card .skill-card:first{
    margin-left: 0px;
}

.icons-card .skill-card .skill-icon, .skills .skill-card .skill-text{
    color: var(--habilidades) !important;
}

.icons-card .skill-card .skill-percentage{
    color: var(--habilidades) !important;
}