@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --white: #eaeaea;
  --black: #050505;
  --sobre: #ee258d;
  --habilidades: #fbe31a;
  --trabalhos: #03aedb;
  --contato: #6f37b7;
  --app-details: #8f9690;
  --main-color: #050505;
  --highlight-color: #050505;

  --image-grid-height: 50vh;
  --image-grid-half-height: calc( var(--image-grid-height)/2 * 1px);
  --image-grid-half-height: calc( var(--image-grid-height)*2 * 1px);
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
