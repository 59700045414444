.blurry-text{
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: transparent;
    color: var(--black);

    filter: blur(20px);
    
    width: 100%;
    height: 100%;

    padding: 0 min(60px, 10vw);
    box-sizing: border-box;
    /* background-color: violet; */

    overflow: hidden;
    user-select: none;

    z-index: -1 !important;
}

.blurry-text .letter{
    position: relative;
    opacity: 0.1;
    text-transform: uppercase;
    font-size: 33vh;
    font-weight: 500;

    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

@media (max-width: 1000px){
    .blurry-text .letter{
        opacity: 0.2;
    }
}

@keyframes drop-letter{
    from{
        transform: translateY(-250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}

@keyframes slide-letter{
    from{
        transform: translateY(250px);
        color: var(--black);
    }
    to{
        transform: translateY(0px);
        color: var(--white);
    }
}