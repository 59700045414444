.page{
    position: relative;
    
    padding: min(60px, 8vw) !important;
    min-height: 100%;
    min-width: 100%;

    box-sizing: border-box;
}

@media (max-width:500px){
    .page{
        padding: 30px !important;
    }
}