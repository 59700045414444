.pages-selector{
    position: absolute;
    bottom: min(40px, 10vw);
    left: 50%;
    transform: translateX(-50%);

    display: flex;

    z-index: 1000;

    user-select: none;
}

.pages-selector *:not(:last-child){
    margin-right: 20px;
}

.pages-selector label{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: max(15px, 1.25vw);
    height: max(15px, 1.25vw);
    background-color: transparent;

    border: 3px solid var(--white);
    border-radius: 50%;

    cursor: pointer;
}

.pages-selector label input{
    position: absolute;
    opacity: 0;
    width: 0;
}

.pages-selector label .page-bubble{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: max(20px, 1.5vw);
    height: max(20px, 1.5vw);
    border-radius: 50%;
    background-color: transparent;

    transition: all 0.5s ease-out;
}

.pages-selector label input:checked+.page-bubble{
    background-color: var(--white);
}

.pages-selector label .page-name{
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0);

    color: var(--white);
    font-size: 1.4em;
    text-transform: lowercase;

    opacity: 0;

    transition: all 0.5s ease-out;
}

.pages-selector label:hover .page-name{
    transform: translate(-50%, -30%);
    opacity: 1;
}