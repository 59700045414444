.glitch-text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: max-content;
    height: min-content;
    font-weight: 700;

    user-select: none;
}

.glitch-text .repetitions{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.glitch-text span{
    font-size: 3em;
    font-weight: inherit;
    letter-spacing: -0.05em;
    line-height: 0.7em;

    margin: 0;
    padding: 0;
}

.glitch-text .auxiliar-text{
    color: transparent;
    -webkit-text-stroke: 1px black;
    opacity: 0;

    animation: drop 1s ease-out forwards;
}

@keyframes drop{
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    30%{
        opacity: 1;
        transform: translateY(0px);
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}